<template src="./EditVoucherCampaign.html"></template>
<script>
import createVoucherWithEmail from "../createVoucher/createVoucherWithEmail/createVoucherWithEmail.vue";
import createVoucherWithBarcode from "../createVoucher/createVoucherWithBarcode/createVoucherWithBarcode.vue";
import createVoucherWithDigitalPass from "../createVoucher/createVoucherWithDigitalPass/createVoucherWithDigitalPass.vue";

export default {
  components: {
    updateVoucherWithEmail: createVoucherWithEmail,
    updateVoucherWithBarcode: createVoucherWithBarcode,
    updateVoucherWithDigitalPass: createVoucherWithDigitalPass,
  },
  data() {
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      type: "1",
    };
  },
  mounted() {
    this.type = +this.$route.query.voucher_type;
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
  },
};
</script>

<style></style>
